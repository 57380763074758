

.react-confirm-alert-overlay {
    z-index: 9999999999999;
}

#react-confirm-alert{
    z-index: 9999999999999;
}


@media (max-width: 40em) {

    .p-dataview .car-details,
    .p-dataview .search-icon,
    .p-dataview .car-images {
      text-align: center;
      margin-top: 0;
    }
  
    .p-dataview .filter-container {
      text-align: left;
    }
  
    .p-dataview-layout-options.p-buttonset>.p-button {
      margin-left: 0;
      display: inline-block;
    }
  
    .p-dataview-layout-options.p-buttonset>.p-button:first-child {
      border-radius: 50%;
    }
  
    .p-dataview-layout-options.p-buttonset>.p-button:last-child {
      border-radius: 50%;
    }
  }
  
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }
  
  .pagination>li {
    display: inline;
  }
  
  #react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .pagination>.disabled>a,
  .pagination>.disabled>a:focus,
  .pagination>.disabled>a:hover,
  .pagination>.disabled>span,
  .pagination>.disabled>span:focus,
  .pagination>.disabled>span:hover {
    color: #777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
  }
  
  .pagination>li>a,
  .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
  }
  
  .pagination>.active>a,
  .pagination>.active>a:focus,
  .pagination>.active>a:hover,
  .pagination>.active>span,
  .pagination>.active>span:focus,
  .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    cursor: default;
  }
  
  #react-paginate li {
    display: inline-block;
  }
  
  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12,
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12,
  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12,
  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    padding-bottom: 0px;
  }
  
  
  
  
  
  .datatable-style-demo .outofstock {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
  }
  
  .datatable-style-demo .lowstock {
    font-weight: 700;
    color: #FFA726;
  }
  
  .datatable-style-demo .instock {
    font-weight: 700;
    color: #66BB6A;
  }
  
  .datatable-style-demo .row-level1 {
    background-color: #ffeb3b10 !important;
  }
  
  .datatable-style-demo .row-level2 {
    background-color: #8bc34a10 !important;
  }
  
  .datatable-style-demo .row-level3 {
    background-color: #2196f308 !important;
  }
  
  .datatable-style-demo .row-level4 {
    background-color: #fff !important;
  }
  
  
  
  
  
  
  
  
  
  .datatable-style-demo .outofstock {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
  }
  
  .datatable-style-demo .lowstock {
    font-weight: 700;
    color: #FFA726;
  }
  
  .datatable-style-demo .instock {
    font-weight: 700;
    color: #66BB6A;
  }
  
  .datatable-style-demo .row-level1 {
    background-color: #ffeb3b10 !important;
  }
  
  .datatable-style-demo .row-level2 {
    background-color: #8bc34a10 !important;
  }
  
  .datatable-style-demo .row-level3 {
    background-color: #2196f308 !important;
  }
  
  .datatable-style-demo .row-level4 {
    background-color: #fff !important;
  }
  
  ._partner_div {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: red;
    margin-right: 1px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    border-radius: 4px;
    padding-top: 1px;
  }
  
  ._partner_div_2 {
  
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 1px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    border-radius: 4px;
    padding-top: 1px;
    background: #000;
  }
  
  ._partner_div_3 {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 1px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    border-radius: 4px;
    padding-top: 1px;
  
    background: #4caf50;
  }
  
  ._partner_div_4 {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 1px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    border-radius: 4px;
    padding-top: 1px;
  
    background: rgb(255, 114, 20);
  }
  
  ._partner_div_5 {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: red;
    margin-right: 1px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 16px;
    border-radius: 4px;
    padding-top: 1px;
  
  
    background: rgb(211, 183, 1);
  }
  
  
  
  ul#gnb_real>li {
    position: relative;
    float: left;
    padding-left: 10px;
    background-color: #4d927b;
    border: 1px solid #26463a;
    margin-right: 4px;
    color: #ffffff;
    text-shadow: 0 0px 1px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    border-radius: 3px;
    /*box-shadow: 0 0px 2px rgba(0, 0, 0, 0.54);*/
  }
  
  ul#gnb_real>li:hover {
    background-color: #6ebea3;
    cursor: pointer;
  }
  
  ul#gnb_real>li>._alarm {
    background-color: #23463a;
    float: right;
    margin-left: 8px;
    width: 30px;
    height: 24px;
    border-radius: 3px;
    text-align: center;
  }
  
  ul#gnb_real>li._select {
    border-color: #f85303;
    background-color: #ff7b34;
  }
  
  ul#gnb_real>li._select>._alarm {
    background-color: #ae3f08;
  }
  
  ul#gnb_real>li>img {
    position: absolute;
    top: -18px;
    left: 4px;
    display: none;
  }
  
  ul#gnb_sts {
    position: relative;
    list-style: none;
    float: left;
    /* margin-top: -54px; */
    margin-left: 60px;
    overflow: hidden;
    z-index: 1;
  }
  
  ul#gnb_sts>li {
    float: left;
    border-right: 1px solid #80CBC4;
    width: auto;
    /* height: 46px;
    padding: 2px 10px; */
    color: #fff;
  }
  
  ul#gnb_sts>li:last-child {
    border-right-width: 0px;
  }
  
  ul#gnb_sts>li>ul {
    list-style: none;
    overflow: hidden;
  }
  
  ul#gnb_sts>li>ul>li {
    padding: 2px;
    text-align: center;
  }
  
  ul#gnb_sts>li>ul._4x>li {
    float: left;
    text-align: right;
    /* width: 68px; */
  }
  
  ul#gnb_sts>li>ul>li>._value {
    display: inline-block;
    text-align: right;
    width: 70px;
    padding: 1px 4px;
    background: #fff;
    color: #666;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid #26463a;
    border-radius: 4px;
  }
  
  ul#gnb_sts>li>ul>li>._value._min {
    width: 30px;
  }
  
  
  
  
  
  
  
  /* 공지팝업 */
  .pop01_popup1 {
    position: absolute;
    z-index: 1000000000;
  }
  
  .pop01_popup2 {
    position: absolute;
    z-index: 1000000000;
  }
  
  .pop01_popup_wrap {
    float: left;
    z-index: 1000000000;
  }
  
  .pop01_popup_btn_wrap {
    float: right;
    z-index: 1000000000;
  }
  
  .pop01_popup_btn_wrap ul li {
    float: left;
    margin: 0 0 0 5px;
  }
  
  .pop01_popup_btn {
    float: right;
    background: #e89006;
    min-width: 60px;
    height: 36px;
    line-height: 40px;
    padding: 0 15px 0 15px;
    text-align: center;
    display: inline-block;
    font-family: nanumgothic, sans-serif;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
  }
  
  .pop01_popup_box {
    float: left;
    background: #000000;
    border: 5px solid #e89006;
    clear: both;
    z-index: 1000000000;
  }
  
  .pop01_popup_text {
    float: left;
    width: 100%;
    z-index: 1000000000;
  }
  
  .pop01_popup_font1 {
    float: left;
    width: 100%;
    font-family: 'nanumsquare', sans-serif;
    font-size: 22px;
    letter-spacing: -1px;
    font-weight: 700;
    color: #e89006;
    line-height: 40px;
  }
  
  .pop01_popup_font2 {
    float: left;
    width: 100%;
    font-family: 'nanumgothic', sans-serif;
    font-size: 16px;
    letter-spacing: -1px;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
  }
  
  
  
  .is-canceled,
  .is-error {
    background: #ffd3cf !important;
  }
  
  .card {
    font-size: 14px;
  }
  
  table tbody td>div>span {
    color: #5a5c69;
  }
  
  .grid-container {
    display: grid;
  }
  
  
  .grid-container {
    grid-template-columns: 50% 50%;
  }
    
  .poker-card-ho,
  .poker-card-ve {
    display: inline-block;
    border: 1px solid #d9e2e9;
    background: #fff;
    border-radius: 5px;
  }
  
  .poker-card-ve {
    width: 24px;
    height: 38px;
    text-align: center;
  }
  
  .poker-card-ve>* {
    display: block;
  }

  .poker-card-ve b{
    color:#000;
  }

  .poker-card-ve img {
    margin: auto;
    width: 16px;
  }
  
  .poker-card-ho {
    width: 38px;
    height: 24px;
    padding: 1px 5px;
  }
  
  .poker-card-ho img {
    position: relative;
    top: -1px;
    margin-left: 3px;
  }
  
  .player-score,
  .banker-score {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .player-card,
  .banker-card {
    padding: 5px 5px;
  }
  
  .player-card .spade,
  .player-card .clover,
  .banker-card .spade,
  .banker-card .clover {
    color: #212121;
  }
  
  .player-card .heart,
  .player-card .diamond,
  .banker-card .heart,
  .banker-card .diamond {
    color: #d50000;
  }
  
  .player-score>span:first-child,
  .banker-score>span:first-child {
    color: #5a5c69;
  }
  
  .player-score>span:last-child,
  .banker-score>span:last-child {
    font-size: 87.5%;
    float: right;
    position: relative;
    top: 3px;
    right: 0px;
  }
  
  .wBaccarat-bet {}
  
  .wBaccarat-bet table td {
    padding: .5rem;
  }
  
  .wBaccarat-bet table td code {
    margin-left: 5px;
  }
  
  .wBaccarat-bet table tr td:nth-child(1) {
    border-left: 0;
    border-right: 0;
  }
  
  .wBaccarat-bet table tr td:nth-child(2) {
    border-left: 0;
  }
  
  .wBaccarat-bet table tr td:nth-child(3) {
    border-right: 0;
  }
  
  .wBaccarat-bet table tr:first-child td:nth-child(1) {
    border-top: 0;
  }
  
  .wBaccarat-bet table tr:first-child td:nth-child(2) {
    border-top: 0;
  }
  
  .wBaccarat-bet table tr:first-child td:nth-child(3) {
    border-top: 0;
  }
  
  .wBaccarat-bet table tr:last-child td:nth-child(1) {
    border-bottom: 0;
  }
  
  .wBaccarat-bet table tr:last-child td:nth-child(2) {
    border-bottom: 0;
  }
  
  .wBaccarat-bet table tr:last-child td:nth-child(3) {
    border-bottom: 0;
  }
  
  .wSicbo .grid-container {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
  
  .wSicbo img {
    width: 24px;
  }
  
  .wSicbo .grid-container>* {
    margin: auto;
    padding: 5px;
  }
  
  .wSicbo-bet table td {
    padding: .5rem;
  }
  
  .wSicbo-bet table td code {
    margin-left: 5px;
  }
  
  .wSicbo-bet table tr td:nth-child(1) {
    border-left: 0;
    border-right: 0;
  }
  
  .wSicbo-bet table tr td:nth-child(2) {
    border-left: 0;
  }
  
  .wSicbo-bet table tr td:nth-child(3) {
    border-right: 0;
  }
  
  .wSicbo-bet table tr:first-child td:nth-child(1) {
    border-top: 0;
  }
  
  .wSicbo-bet table tr:first-child td:nth-child(2) {
    border-top: 0;
  }
  
  .wSicbo-bet table tr:first-child td:nth-child(3) {
    border-top: 0;
  }
  
  .wSicbo-bet table tr:last-child td:nth-child(1) {
    border-bottom: 0;
  }
  
  .wSicbo-bet table tr:last-child td:nth-child(2) {
    border-bottom: 0;
  }
  
  .wSicbo-bet table tr:last-child td:nth-child(3) {
    border-bottom: 0;
  }
  
  .wRoulette-bet table td {
    padding: .5rem;
  }
  
  .wRoulette-bet table td code {
    margin-left: 5px;
  }
  
  .wRoulette-bet table tr td:nth-child(1) {
    border-left: 0;
    border-right: 0;
  }
  
  .wRoulette-bet table tr td:nth-child(2) {
    border-left: 0;
  }
  
  .wRoulette-bet table tr td:nth-child(3) {
    border-right: 0;
  }
  
  .wRoulette-bet table tr:first-child td:nth-child(1) {
    border-top: 0;
  }
  
  .wRoulette-bet table tr:first-child td:nth-child(2) {
    border-top: 0;
  }
  
  .wRoulette-bet table tr:first-child td:nth-child(3) {
    border-top: 0;
  }
  
  .wRoulette-bet table tr:last-child td:nth-child(1) {
    border-bottom: 0;
  }
  
  .wRoulette-bet table tr:last-child td:nth-child(2) {
    border-bottom: 0;
  }
  
  .wRoulette-bet table tr:last-child td:nth-child(3) {
    border-bottom: 0;
  }
  
  .wRoulette .grid-container {
    grid-template-columns: repeat(13, 1fr);
  }
  
  .wRoulette .grid-container .roulette-green {
    grid-row-start: 1;
    grid-row-end: 4;
  }
  
  .wRoulette .grid-item {
    border-right: 1px solid #e3e6f0;
    border-top: 1px solid #e3e6f0;
    height: 24px;
    text-align: center;
    color: #fff;
  }
  
  .wRoulette .grid-item:first-child {
    border-left: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
  }
  
  .wRoulette .grid-item:nth-child(14),
  .wRoulette .grid-item:nth-child(26) {
    border-left: 1px solid #e3e6f0;
  }
  
  .wRoulette .grid-item:nth-child(26),
  .wRoulette .grid-item:nth-child(27),
  .wRoulette .grid-item:nth-child(28),
  .wRoulette .grid-item:nth-child(29),
  .wRoulette .grid-item:nth-child(30),
  .wRoulette .grid-item:nth-child(31),
  .wRoulette .grid-item:nth-child(32),
  .wRoulette .grid-item:nth-child(33),
  .wRoulette .grid-item:nth-child(34),
  .wRoulette .grid-item:nth-child(35),
  .wRoulette .grid-item:nth-child(36),
  .wRoulette .grid-item:nth-child(37) {
    border-bottom: 1px solid #e3e6f0;
  }
  
  .wRoulette .grid-item.roulette-green {
    background: #167937;
  }
  
  .wRoulette .grid-item.roulette-red {
    background: #b7311a;
  }
  
  .wRoulette .grid-item.roulette-black {
    background: #000000;
  }
  
  .wRoulette .pick {
    position: relative;
  }
  
  .wRoulette .pick img {
    width: 28px;
    position: absolute;
    top: -21px;
    left: -4px;
  }
  
  .roulette-result .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .roulette-result .grid-container>* {
    margin: auto;
  }
  
  .roulette-result .roulette-green {
    color: #167937;
  }
  
  .roulette-result .roulette-red {
    color: #b7311a;
  }
  
  .roulette-result .roulette-black {
    color: #000000;
  }
  
  .wLighting .grid-container>.grid-item:nth-child(3),
  .wLighting .grid-container>.grid-item:nth-child(4) {
    border-bottom: 1px solid #eaeaea;
  }
  
  .wBacbo .grid-container {
    grid-template-columns: 25% 25% 25% 25%;
  }
  
  .wBacbo .grid-container .dice-total {
    color: #5a5c69;
    margin: 0;
  }
  
  .wSicbo .grid-container .dice-sb>span {
    color: #5a5c69;
    font-size: 87.5%;
    float: right;
    position: relative;
    right: 0px;
  }
  
  .wTable td:first-child,
  .wTable th:first-child {
    padding-left: 0 !important;
  }
  
  .wTable td:last-child,
  .wTable th:last-child {
    padding-right: 0 !important;
  }
  
  .wBlackjack .grid-container>.grid-item {
    border-top: 1px solid #eaeaea;
  }
  
  .wBlackjack .grid-container>.grid-item:nth-child(1),
  .wBlackjack .grid-container>.grid-item:nth-child(2) {
    border-top: 0;
  }
  
  .wSicboLuck .grid-container>.grid-item:nth-child(1),
  .wSicboLuck .grid-container>.grid-item:nth-child(2) {
    border-top: 1px solid #eaeaea;
  }
  
  .smallFontsize {
    font-size: 87.5% !important;
  }